<script>
import VuetableFieldCheckbox from '@src/components/vuetable/VuetableFieldCheckbox.vue'
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import UsersService from '@src/services/UsersService'
import WorkScheduleService from '@src/services/WorkScheduleService'
import WorkScheduleItemService from '@src/services/WorkScheduleItemService'
import Layout from '@layouts/main.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import DateRangePicker from '@/src/components/DateRangePicker'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import workScheduleModal from '@views/workSchedule/workScheduleModal.vue'
import workScheduleCloneModal from '@views/workSchedule/workScheduleCloneModal.vue'
import workScheduleFilterModal from '@views/workSchedule/workScheduleFilterModal.vue'
import mixProductModal from '@views/mixes/mixProductModal'
import FilterBar from '@src/components/FilterBar'
import workScheduleFrequencies from '@src/mixins/workScheduleFrequencies'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import pagination from '@src/mixins/pagination.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import importModal from '@views/importJob/importJobModal'
import AddButton from '@/src/components/AddLabelButton.vue'
import ImportButton from '@/src/components/ImportButton.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import EyeTableButton from '@/src/components/EyeTableButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import DeleteAllButton from '@/src/components/DeleteAllButton.vue'
import DeleteSelectedButton from '@/src/components/DeleteSelectedButton.vue'
import WorkscheduleItemCard from '@/src/router/views/workSchedule/WorkscheduleItemCard.vue'
import priorityTypes from '@src/mixins/priorityTypes.vue'
import moment from 'moment';
import { debounce } from 'lodash'

const i18nKey = 'WORKSCHEDULES'
const i18nCommon = 'COMMON'

export default {
    components: {
        ApiModeVuetable,
        Layout,
        Breadcrumb,
        DateRangePicker,
        ConfirmationFormModal,
        FeedbackModal,
        workScheduleModal,
        workScheduleCloneModal,
        workScheduleFilterModal,
        mixProductModal,
        FilterBar,
        importModal,
        AddButton,
        ImportButton,
        ExportButton,
        FilterLabelButton,
        EyeTableButton,
        MoreTableButton,
        DeleteAllButton,
        DeleteSelectedButton,
        WorkscheduleItemCard,
    },
    mixins: [
        pagination,
        swalFeedback,
        workScheduleFrequencies,
        vuetableFormatters,
        filterVuetable,
        priorityTypes,
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'updated_at',
                    direction: 'desc',
                },
            ],
            i18nCommon,
            i18nKey,
            tabIndexCalendar: 0,
            descriptionConfirmationModal: '',
            deleteType: 'delete_selected',
            deleteSelectedLoading: false,
            deleteAllLoading: false,
            loadingCalendar: true,
            loadingUser: false,
            dataCalendar: [],
            modalIsEdit: false,
            modal: {
                id: null,
                pos_id: null,
                pos: {
                    id: 0,
                    name: '',
                },
                user_id: null,
                user: {
                    id: 0,
                    name: '',
                },
                mix_id: null,
                mix: {
                    id: 0,
                    name: '',
                    business_unit: {
                        id: 0,
                        name: '',
                    },
                    products: [],
                },
                start_date: null,
                end_date: null,
                priority_order: '',
                human_readable_frequencies: [],
            },
            additionalParametersGrid: {
                with: ['mix', 'pointOfSale', 'pointOfSale.chain', 'user'],
                user_id: [this.$route.params.id]
            },
            data: [],
            perPage: 25,
            fields: [
                {
                    name: VuetableFieldCheckbox,
                    titleClass: 'center aligned',
                    dataClass: 'center aligned',
                    togglable: true,
                },
                {
                    name: 'mix.name',
                    title: this.getI18n('MIXES', 'TITLES.mix'),
                    sortField: 'mixes|mixes.name',
                },
                {
                    name: 'pointOfSale.name',
                    title: this.getI18n('POS', 'TITLES.pos'),
                    sortField: 'point_of_sales|point_of_sales.name',
                },
                {
                    name: 'pointOfSale.chain.name',
                    title: this.getI18n('POS', 'TITLES.chain'),
                },
                {
                    name: 'human_readable_frequency',
                    title: this.getI18n('COMMON', 'frequency'),
                    formatter: this.formatFrequency,
                },
                {
                    name: 'start',
                    title: this.getI18n(i18nCommon, 'start'),
                    sortField: 'start',
                    formatter: this.formatDate,
                },
                {
                    name: 'end',
                    title: this.getI18n(i18nCommon, 'end'),
                    sortField: 'end',
                    formatter: this.formatDate,
                },
                {
                    name: 'priority_order',
                    title: this.getI18n(i18nKey, 'FIELDS.priority'),
                    sortField: 'priority_order',
                    formatter: this.priorityTypeToText
                },
                {
                    name: 'products',
                    title: this.getI18nModified({
                        prefix: 'PRODUCTS',
                        suffix: 'TITLES.product',
                        modifier: 2,
                    }),
                },
                {
                    name: 'updated_at',
                    title: this.getI18n(i18nCommon, 'updated_at'),
                    sortField: 'updated_at',
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            filterText: '',
            filtersCalendar: {
                date: {
                    start: moment().startOf('day').toDate(),
                    end: moment().startOf('day').add(30, 'days').toDate(),
                },
                user_id: this.$route.params.id,
            },
            filtersGrid: {
                pos: [],
                mixes: [],
                frequency: [],
                start: null,
                end: null,
            },
            user: {},
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.work_schedule',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.user',
                        modifier: 2,
                    }),
                    href: '/workschedules'
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.work_schedule',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nCalendar() {
            return this.getI18n(i18nCommon, 'calendar')
        },
        i18nWorkSchedule() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.work_schedule',
                modifier: 2,
            })
        },
        i18nNewWorkSchedule() {
            return this.getI18n(i18nKey, 'BUTTONS.new_work_schedule')
        },
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nBack() {
            return this.getI18n(i18nCommon, 'back')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParametersGrid = {
                search: '',
                with: ['mix', 'pointOfSale', 'pointOfSale.chain', 'user'],
            }

            if (searchText && searchText.length > 0) {
                this.additionalParametersGrid.search = `user.name:${searchText};pointOfSale.name:${searchText};mix.name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    mounted() {
        this.loadingUser = true
        UsersService
            .fetchById(this.$route.params.id)
            .then(response => {
                this.user = response.data.data
            })
            .finally(() => {
                this.loadingUser = false
            })
        this.refreshCalendar(0)
    },
    methods: {
        createAdditionalParametersGrid() {
            return this.formatParameters({
                with: ['mix', 'pointOfSale', 'pointOfSale.chain', 'user'],
                mix_id: this.filtersGrid.mixes.map((mix) => mix.id),
                point_of_sale_id: this.filtersGrid.pos.map((pos) => pos.id),
                frequency: this.filtersGrid.frequency,
                start: this.formatFilterDate(this.filtersGrid.start),
                end: this.formatFilterDate(this.filtersGrid.end),
            })
        },

        fetch(url, params) {
            return new Promise((resolve, reject) => {
                WorkScheduleService.fetchVuetable(url, params)
                    .then(response => {
                        this.data = response.data.data;

                        resolve(response);

                    }).catch(error => reject(error))
            });
        },

        cleanModal() {
            this.$nextTick(() => this.$refs.workScheduleModal.cleanModal())
        },

        showModal(date) {
            this.$nextTick(() => this.$refs.workScheduleModal.showModal(this.user, date))
        },

        showImportModal() {
            this.$nextTick(() => this.$refs.importModal.showModal())
        },

        openModalToCreate(date) {
            this.modalIsEdit = true
            this.cleanModal()
            this.showModal(date)
        },

        openModalToEdit(workSchedule) {
            this.modalIsEdit = false
            this.replaceModalData(workSchedule)
            this.showModal()
        },

        openModalProducts(data) {
            this.$nextTick(() => this.$refs.mixProductModal.showModal(data.mix.id, data.mix.name))
        },

        replaceModalData(data) {
            Object.assign(this.modal, {
                id: data.id,
                pos_id: data.point_of_sale_id,
                pos: data.pointOfSale,
                user_id: data.user_id,
                user: data.user,
                mix_id: data.mix_id,
                mix: data.mix,
                start_date: data.start,
                end_date: data.end,
                last_start_date: data.start,
                priority_order: data.priority_order,
                human_readable_frequencies: data.human_readable_frequency,
            })

            if (this.modal.start_date) {
                this.modal.start_date = moment(this.modal.start_date, 'YYYY-MM-DD').toDate();
            }

            if (this.modal.end_date) {
                this.modal.end_date = moment(this.modal.end_date, 'YYYY-MM-DD').toDate();
            }

            if (this.modal.last_start_date) {
                this.modal.last_start_date = moment(this.modal.last_start_date, 'YYYY-MM-DD').toDate();
            }
        },
        filterCalendar() {
            this.refreshCalendar(0)
        },
        filterGrid(appliedFilters) {
            this.filtersGrid = appliedFilters
            this.additionalParametersGrid = this.createAdditionalParametersGrid()
            this.refreshTable()
        },
        showFilterModal() {
            this.$nextTick(() =>
                this.$refs.workScheduleFilterModal.showModal(this.filtersGrid)
            )
        },
        refreshData() {
            this.refreshCalendar(this.tabIndexCalendar)
            this.refreshTable()
        },
        reloadTable() {
            this.$refs.vuetable.reload()
        },
        refreshTable() {
            this.$refs.vuetable.refresh()
        },
        refreshCalendar(tabIndex) {
            this.loadingCalendar = true
            WorkScheduleItemService.fetchAll({
                start: moment(this.filtersCalendar.date.start).format('YYYY-MM-DD'),
                end: moment(this.filtersCalendar.date.end).format('YYYY-MM-DD'),
                user_id: this.$route.params.id,
            }).then(response => {
                this.dataCalendar = []
                response.data.forEach(week => {
                    const weekData = Object.keys(week).map((key) => [key, week[key]])
                    const days = []

                    weekData.forEach(day => {
                        days.push({
                            date: day[0],
                            items: day[1],
                            expanded: days.length === 0,
                        })
                    })

                    this.dataCalendar.push({
                        start_date: weekData[0][0],
                        end_date: weekData[weekData.length - 1][0],
                        days,
                    })
                })
            }).finally(() => {
                this.loadingCalendar = false
                this.tabIndexCalendar = tabIndex
            })
        },
        handleDelete(type) {
            if (type === 'delete_selected' && this.deleteSelectedLoading) {
                return
            } else if (type === 'delete_all' && this.deleteAllLoading) {
                return
            }

            this.descriptionConfirmationModal = ''
            this.deleteType = type
            if (type === 'delete_selected') {
                this.$refs.confirmationFormModal.showModal(false, this.deletedSelected)
            }
            else if (type === 'delete_all') {
                this.$refs.confirmationFormModal.showModal(false, this.deletedAllFiltered)
            }
        },
        async deletedSelected(deleteRecord) {
            if (!deleteRecord) {
                return
            }

            if (this.$refs.vuetable.$refs.vuetable.selectedTo.some(row => {
                const selectedItem = this.data.find(item => parseInt(item.id) === parseInt(row));
                if (selectedItem) {
                    const now = Date.now();
                    const daysOfWeek = ['DAY_OF_WEEK_SUNDAY', 'DAY_OF_WEEK_MONDAY', 'DAY_OF_WEEK_TUESDAY', 'DAY_OF_WEEK_WEDNESDAY', 'DAY_OF_WEEK_THURSDAY', 'DAY_OF_WEEK_FRIDAY', 'DAY_OF_WEEK_SATURDAY'];
                    const currentDayOfWeek = daysOfWeek[(new Date()).getDay()];

                    if ((selectedItem.human_readable_frequency.includes(currentDayOfWeek) || selectedItem.human_readable_frequency.includes('ANY_DAY_OF_WEEK'))
                        && now >= (new Date(selectedItem.start)).getTime() && now <= (new Date(selectedItem.end)).getTime()) {

                        this.deleteType = 'deleted';
                        this.$refs.feedbackModal.showModal(401, 'Alguma das lojas selecionada está em um roteiro corrente, não é possível apagar.');
                        return true;
                    }
                }
            })) {
                return;
            }

            this.deleteSelectedLoading = true
            await this.deleteWorkSchedule(
                WorkScheduleService.deleteMultiple({
                    work_schedule_id: this.$refs.vuetable.$refs.vuetable.selectedTo,
                })
            )
        },
        async deletedAllFiltered(deleteRecord) {
            if (!deleteRecord) {
                return
            }

            this.deleteAllLoading = true
            await this.deleteWorkSchedule(
                WorkScheduleService.deleteMultiple(
                    this.additionalParametersGrid
                )
            )
        },
        async deleteWorkSchedule(deleteFunction) {
            const response = await deleteFunction
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$refs.feedbackModal.showModal(statusCode, response.data)
                this.refreshTable()
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }
            this.deleteSelectedLoading = false
            this.deleteAllLoading = false
        },
        handleExpandCard(date, week) {
            this.dataCalendar[week].days.forEach(day => {
                day.expanded = day.date === date
            })
        },
        handleCopyWorkScheduleItem(data) {
            this.$refs.workScheduleCloneModal.showModal(data)
        },
        handleDeleteWorkScheduleItem(date, pointOfSaleName, workScheduleId) {
            this.deleteType = pointOfSaleName ? 'deleted' : 'delete_all'

            if (pointOfSaleName) {
                this.descriptionConfirmationModal = `${this.getI18n(i18nCommon, 'delete')} ${pointOfSaleName} ${this.getI18n(i18nCommon, 'from').toLowerCase()} ${this.formatDate(date)}`
                this.$refs.confirmationFormModal.showModal(false, this.deletedSelectedWorkScheduleItem, { date, workScheduleId })
            }
            else {
                this.descriptionConfirmationModal = `${this.getI18n(i18nCommon, 'delete_all')} ${this.i18nWorkSchedule.toLowerCase()} ${this.getI18n(i18nCommon, 'from').toLowerCase()} ${this.formatDate(date)}`
                this.$refs.confirmationFormModal.showModal(false, this.deletedAllWorkScheduleItem, { date, workScheduleId, })
            }
        },
        handlAnswerQuestionnaire(date, workScheduleId, pointOfSaleId) {
            this.$router.push({ name: 'answerWorkScheduleForUser', params: { id: this.$route.params.id, workScheduleId, pointOfSaleId, date } })
        },
        async deletedSelectedWorkScheduleItem(deleteRecord, item) {
            if (!deleteRecord) {
                return
            }

            const today = new Date();
            today.setHours(12, 0, 0, 0);
            const calendar = new Date(`${item.date} 12:00:00`);

            if (today.getTime() === calendar.getTime()) {
                this.deleteType = 'deleted';
                this.$refs.feedbackModal.showModal(401, 'Não é permitido apagar roteiros do dia corrente.');
                return;
            }

            const response = await WorkScheduleItemService
                .delete(null, {
                    date: item.date,
                    work_schedule_id: item.workScheduleId[0],
                })
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.positiveFeedback(this.i18nWorkSchedule, 'deleted', 3)
                this.refreshCalendar(this.tabIndexCalendar)
                this.refreshTable()
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }
        },
        async deletedAllWorkScheduleItem(deleteRecord, item) {
            if (!deleteRecord) {
                return
            }

            const today = new Date();
            today.setHours(12, 0, 0, 0);
            const calendar = new Date(`${item.date} 12:00:00`);

            if (today.getTime() === calendar.getTime()) {
                this.deleteType = 'deleted';
                this.$refs.feedbackModal.showModal(401, 'Não é permitido apagar roteiros do dia corrente.');
                return;
            }

            const response = await WorkScheduleItemService
                .deleteMultiple({
                    date: item.date,
                    work_schedule_id: item.workScheduleId,
                })
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$refs.feedbackModal.showModal(statusCode, response.data)
                this.refreshCalendar(this.tabIndexCalendar)
                this.refreshTable()
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }
        },
        goToBack() {
            this.$router.push({
                name: 'workSchedule',
            })
        },
    },
    metaInfo() {
        return {
            title: this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.work_schedule',
                modifier: 2,
            }),
        }
    },
}
</script>
<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="d-flex justify-content-between">
                <b-skeleton v-if="loadingUser" type="button" width="35%" class="mt-1"/>
                <h3 v-else class="mb-0">{{user.name}}</h3>
                <b-button
                    class="btn btn-pdv-gradient-blue-transparent"
                    size="sm"
                    @click="goToBack"
                >
                    <i class="fe-corner-up-left" />
                    {{ i18nBack }}
                </b-button>
            </div>
            <b-tabs class="tabs-pdv mt-2">
                <b-tab :title="i18nCalendar" :disabled="deleteSelectedLoading || deleteAllLoading">
                    <div class="d-flex justify-content-between align-items-center">
                        <b-form-group
                            :label="i18nPeriod"
                            class="required label-pdv"
                            label-for="filter-date"
                        >
                            <date-range-picker
                                :value.sync="filtersCalendar.date"
                                :disable-clean-button="true"
                                :disabled="loadingCalendar"
                                @onChange="filterCalendar"
                            />
                        </b-form-group>
                    </div>
                    <template v-if="loadingCalendar">
                        <div class="row mb-3">
                            <b-col md="2">
                                <b-skeleton type="input" width="100%" />
                            </b-col>
                            <b-col md="2">
                                <b-skeleton type="input" width="100%"/>
                            </b-col>
                            <b-col md="2">
                                <b-skeleton type="input" width="100%"/>
                            </b-col>
                            <b-col md="2">
                                <b-skeleton type="input" width="100%"/>
                            </b-col>
                            <b-col md="2">
                                <b-skeleton type="input" width="100%"/>
                            </b-col>
                        </div>
                        <div class="d-flex justify-content-between">
                            <workschedule-item-card :expanded="true" :busy="true" />
                            <workschedule-item-card :busy="true" />
                            <workschedule-item-card :busy="true" />
                            <workschedule-item-card :busy="true" />
                            <workschedule-item-card :busy="true" />
                            <workschedule-item-card :busy="true" />
                            <workschedule-item-card :busy="true" />
                        </div>
                    </template>
                    <b-tabs v-else class="tabs-pdv">
                        <b-tab
                            v-for="(week, index) in dataCalendar"
                            :key="index"
                            v-model="tabIndexCalendar"
                            :title="`${formatDateShort(week.start_date)} - ${formatDateShort(week.end_date)}`"
                        >
                            <div class="d-flex">
                                <workschedule-item-card
                                    v-for="day in week.days"
                                    :key="day.date"
                                    :item="day"
                                    :expanded="day.expanded"
                                    :week="index"
                                    @expandCard="handleExpandCard"
                                    @addItem="openModalToCreate"
                                    @copyItem="handleCopyWorkScheduleItem"
                                    @deleteItem="handleDeleteWorkScheduleItem"
                                    @answerQuestionnaire="handlAnswerQuestionnaire"
                                />
                            </div>
                        </b-tab>
                    </b-tabs>
                </b-tab>
                <b-tab :title="i18nWorkSchedule">
                    <div class="row mb-3">
                        <div class="col-sm-6 form-inline">
                            <filter-bar v-model="filterText" />
                            <filter-label-button @onClick="showFilterModal" />
                        </div>
                        <div class="col-sm-6">
                            <div class="text-sm-right">
                                <add-button
                                    v-if="$can('CREATE', 'WORKSCHEDULE')"
                                    :title="i18nNewWorkSchedule"
                                    :disabled="loadingUser"
                                    @onClick="openModalToCreate"
                                />
                                <import-button
                                    v-if="$can('CREATE', 'IMPORTJOB')"
                                    @onClick="showImportModal"
                                />
                                <export-button
                                    v-if="$can('CREATE', 'EXPORTJOB')"
                                    type="work_schedule"
                                    :create-parameters="createAdditionalParametersGrid"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="card card-pdv">
                        <b-row>
                            <div class="col-sm-6">
                            </div>
                            <div class="col-sm-6">
                                <div class="text-sm-right">
                                    <delete-selected-button
                                        v-if="$can('DELETE', 'WORKSCHEDULE')"
                                        :busy="deleteSelectedLoading"
                                        :disabled="deleteAllLoading"
                                        @onClick="handleDelete('delete_selected')"
                                    />
                                    <delete-all-button
                                        v-if="$can('DELETE', 'WORKSCHEDULE')"
                                        :busy="deleteAllLoading"
                                        :disabled="deleteSelectedLoading"
                                        @onClick="handleDelete('delete_all')"
                                    />
                                </div>
                            </div>
                        </b-row>
                        <div class="card-body">
                            <api-mode-vuetable
                                ref="vuetable"
                                :fetch-data="fetch"
                                :fields="fields"
                                :per-page="perPage"
                                :display-selection-records-per-page="true"
                                :additional-parameters="additionalParametersGrid"
                                :sort-order="sortOrder">
                                <template slot="products" slot-scope="props">
                                    <eye-table-button @onClick="openModalProducts(props.rowData)" />
                                </template>
                                <template slot="actions" slot-scope="props">
                                    <div class="table-button-container">
                                        <more-table-button @onClick="openModalToEdit(props.rowData)" />
                                    </div>
                                </template>
                            </api-mode-vuetable>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
        <confirmation-form-modal
            ref="confirmationFormModal"
            :title="i18nWorkSchedule"
            :description="descriptionConfirmationModal"
            :operation="deleteType"
        />
        <feedback-modal
            ref="feedbackModal"
            :title="i18nWorkSchedule"
            :operation="deleteType"
        />
        <work-schedule-modal
            ref="workScheduleModal"
            :modal="modal"
            :modal-is-edit="modalIsEdit"
            @refresh="refreshData"
        />
        <work-schedule-clone-modal
            ref="workScheduleCloneModal"
            :user-id="$route.params.id"
            @refresh="refreshData"
        />
        <import-modal
            ref="importModal"
            :csv-type="'work_schedule'"
            :title="
                getI18nModified({
                    prefix: 'IMPORT_JOBS',
                    suffix: 'TITLES.work_schedule',
                    modifier: 2,
                })
            "
        />
        <work-schedule-filter-modal
            ref="workScheduleFilterModal"
            :handle-submit="filterGrid"
        />
        <mix-product-modal
            ref="mixProductModal"
        />
    </Layout>
</template>
